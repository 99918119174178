import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [codAulaTeorica, setCodAulaTeorica] = useState(null);
  const [codAluno, setCodAluno] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  return (
    <AppContext.Provider value={{ codAulaTeorica, setCodAulaTeorica, codAluno, setCodAluno, capturedImage, setCapturedImage }}>
      {children}
    </AppContext.Provider>
  );
};
